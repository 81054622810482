// https://api.worldbank.org/v2/country?format=json

import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';



function getCountries() {
    return fetch('https://api.worldbank.org/v2/country?format=json')
      .then((response) => response.json())
      .then((data) => {
        // console.log(data[1][0].name);
        return data[1].map((country) => ({ title: country.name+'-'+country.iso2Code, key: country.iso2Code}));
      });
}

const westCountries=[
  {title:"United States - US",key:"US"},
  {title:"Canada - CA",key:"CA"},
]
export const countryRegions = {
  US: [
    { title: "Alabama", key: "AL" },
    { title: "Alaska", key: "AK" },
    { title: "Arizona", key: "AZ" },
    { title: "Arkansas", key: "AR" },
    { title: "California", key: "CA" },
    { title: "Colorado", key: "CO" },
    { title: "Connecticut", key: "CT" },
    { title: "Delaware", key: "DE" },
    { title: "District of Columbia", key: "DC" },  // Not a state, often included for reference.
    { title: "Florida", key: "FL" },
    { title: "Georgia", key: "GA" },
    { title: "Hawaii", key: "HI" },
    { title: "Idaho", key: "ID" },
    { title: "Illinois", key: "IL" },
    { title: "Indiana", key: "IN" },
    { title: "Iowa", key: "IA" },
    { title: "Kansas", key: "KS" },
    { title: "Kentucky", key: "KY" },
    { title: "Louisiana", key: "LA" },
    { title: "Maine", key: "ME" },
    { title: "Maryland", key: "MD" },
    { title: "Massachusetts", key: "MA" },
    { title: "Michigan", key: "MI" },
    { title: "Minnesota", key: "MN" },
    { title: "Mississippi", key: "MS" },
    { title: "Missouri", key: "MO" },
    { title: "Montana", key: "MT" },
    { title: "Nebraska", key: "NE" },
    { title: "Nevada", key: "NV" },
    { title: "New Hampshire", key: "NH" },
    { title: "New Jersey", key: "NJ" },
    { title: "New Mexico", key: "NM" },
    { title: "New York", key: "NY" },
    { title: "North Carolina", key: "NC" },
    { title: "North Dakota", key: "ND" },
    { title: "Ohio", key: "OH" },
    { title: "Oklahoma", key: "OK" },
    { title: "Oregon", key: "OR" },
    { title: "Pennsylvania", key: "PA" },
    { title: "Rhode Island", key: "RI" },
    { title: "South Carolina", key: "SC" },
    { title: "South Dakota", key: "SD" },
    { title: "Tennessee", key: "TN" },
    { title: "Texas", key: "TX" },
    { title: "Utah", key: "UT" },
    { title: "Vermont", key: "VT" },
    { title: "Virginia", key: "VA" },
    { title: "Washington", key: "WA" },
    { title: "West Virginia", key: "WV" },
    { title: "Wisconsin", key: "WI" },
    { title: "Wyoming", key: "WY" }
  ],
  CA: [
    { title: "Alberta", key: "AB" },
    { title: "British Columbia", key: "BC" },
    { title: "Manitoba", key: "MB" },
    { title: "New Brunswick", key: "NB" },
    { title: "Newfoundland and Labrador", key: "NL" },
    { title: "Northwest Territories", key: "NT" },
    { title: "Nova Scotia", key: "NS" },
    { title: "Nunavut", key: "NU" },
    { title: "Ontario", key: "ON" },
    { title: "Prince Edward Island", key: "PE" },
    { title: "Quebec", key: "QC" },
    { title: "Saskatchewan", key: "SK" },
    { title: "Yukon", key: "YT" }
  ]

}
/**
 * CountryPicker component used for picking countries. Currentl yonly the states and Canada are options along with the respectiv states.
 * 
 * @param {object} props - The component props.
 * @param {boolean} [props.useAllCountries=false] - Flag indicating whether to use all countries.
 * @param {string} [props.addressCountry] - The address country.
 * @param {boolean} [props.required=false] - Flag indicating whether the field is required.
 * @param {function} props.onChange - The change event handler.
 * 
 * @returns {JSX.Element} The rendered CountryPicker component.
 */
export default function CountryPicker(props,{useAllCountries=false,}) {

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const loading = open && options.length === 0;
  
    React.useEffect(() => {
      let active = true;
      
      if (!loading) {
        return undefined;
      }
  
      (async () => {
        if(useAllCountries){
          const c = await getCountries(); // For loading Countries.
          if (active) {
            setOptions(c);
          }
  
        if (active) {
          setOptions(c);
        }
      }else if(active){
        if(props.addressCountry){
          console.log(props.addressCountry);
          setOptions(countryRegions[props.addressCountry]);
        }else{
          setOptions(westCountries);
        }
      }
      })();
  
      return () => {
        active = false;
      };
    }, [loading]);
  
    React.useEffect(() => {
      if (!open) {
        setOptions([]);
      }
    }, [open]);
  
    return (
      <Autocomplete
      aria-required = {props.required || false}
        id="country-picker"
        sx={{ width: 300 }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(event, newValue) =>{
          // console.log(newValue?.key);
          return props.onChange(newValue?.key)}}
        isOptionEqualToValue={(option, value) => option.title === value.title}
        getOptionLabel={(option) => option.title}
        options={options}
        loading={loading}
        renderInput={(params) => (
          <TextField

            {...params}
            label={props.addressCountry ?"Choose Region" : "Choose Country"}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }