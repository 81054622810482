import * as React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
// import ToggleColorMode from './ToggleColorMode';
import AccountBoxIcon from '@mui/icons-material/ManageAccountsSharp';
import VerifiedIcon from '@mui/icons-material/Verified';
import HomeIcon from '@mui/icons-material/Home';
import logo from '../../assets/images/banner.png';
import NotificationsIcon from '@mui/icons-material/NotificationImportant';
import { Link, useLocation,useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LoginIcon from '@mui/icons-material/Login';

const logoStyle = {
  width: '200px',
  height: 'auto',
  cursor: 'pointer',
};
const icons = {
  Home: <HomeIcon/>,
  Issue: <VerifiedIcon/>,
  Account: <AccountBoxIcon/>,
  'Log In' : <LoginIcon/>,
  Register: <HowToRegIcon/>
}





/**
 * Renders the application's app bar.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.mode - The mode of the app.
 * @param {function} props.toggleColorMode - The function to toggle the color mode.
 * @param {Array} props.options - The options for the app bar.
 * @param {Object} props.client - The client object.
 * @param {Object} props... - The remaining props.
 * @returns {JSX.Element} The rendered app bar component.
 */
function AppAppBar({ mode, toggleColorMode ,options,client,...props}) {
  
  const [open, setOpen] = React.useState(false);
  const location = useLocation().pathname;
  // console.log(location);
  const navigate = useNavigate();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const trigger = useScrollTrigger();
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar
        position="fixed"
        
        sx={{
          top: 0,
          left: '10%',
          width: '80%',
          // border:'1px solid black',
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 2,
          zIndex: open ? 0 : 9999
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              borderRadius: '999px',
              bgcolor:
                theme.palette.mode === 'light'
                  ? 'rgba(255, 255, 255, 0.4)'
                  : 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(24px)',
              maxHeight: 40,
              border: '1px solid',
              borderColor: 'divider',
              boxShadow:
                theme.palette.mode === 'light'
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : '0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)',
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                ml: '-18px',
                px: 0,
                justifyContent: (options?.[0] ==='Home' ? 'flex-start':'space-between'),
              }}
            >
              <img
                src={
                  logo
                }
                style={logoStyle}
                alt="logo"
                
              />
              <Stack sx={{ display: { xs: 'none', md: 'flex' } ,
            
            }} direction='row' spacing={2}>
                {options.map((option)=>
                <Button
                  onClick={() => navigate('/'+(option==='Home'? '' :option.replace(' ','')))}
                  sx={{ py: '6px', px: '24px' ,borderRadius: '999px'}}
                  variant='contained'
                  color='inherit' 
                >
                  <Stack  direction="row"spacing={1} alignItems='center'  color='text.primary'>
                    {icons[option]}
                  <Typography variant="body2" color="text.primary" alignSelf='center'>
                   {option}
                  </Typography>
                  </Stack>
                </Button>
              )}
                {/* <MenuItem
                  onClick={() => navigate('/Issue')}
                  sx={{ py: '6px', px: '12px' }}
                >
                     <Stack  direction="row"spacing={1} alignItems='center'   color='text.primary'>
                    {location==='/Issue' ? <VerifiedIcon fontSize='large'/> : <VerifiedOutlinedIcon/>}
                  <Typography variant="body2" color="text.primary" alignSelf='center'>
                   Issue a Credential
                  </Typography>
                  </Stack>
                </MenuItem> */}
                {/* <MenuItem
                  onClick={() => scrollToSection('faq')}
                  sx={{ py: '6px', px: '12px' }}
                >
                  <Typography variant="body2" color="text.primary">
                    FAQ
                  </Typography>
                </MenuItem> */}
              </Stack>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
              {/* <ToggleColorMode mode={mode} /> */}
             {options[0]==='Home'&& <MenuItem
                  onClick={() => navigate('/Account')}
                  sx={{ py: '6px', px: '12px' }}
                >
                    <Stack  direction="row" spacing={1} alignItems='center'   color='text.primary'>
                 {(client && client.requests.length > 0) ?<NotificationsIcon color='error'/>
                     : (location === '/Account' ? <AccountBoxIcon fontSize='large'/> : <ManageAccountsOutlinedIcon/>)}
                  <Typography variant="body2" color="text.primary" alignSelf='center'>
                    Account
                  </Typography>
                  </Stack>
                </MenuItem>}
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                  </Box>
                  {options.map((option)=>
                
                  <MenuItem onClick={() => navigate('/'+(option==='Home'? '' :option.replace(' ','')))}>
                    {option}
                  </MenuItem>)}
                    {options[0]==='Home'&& <>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      onClick={() => navigate('/Account')}
                      target="_blank"
                      sx={{ width: '100%' }}
                      startIcon={(client && client.requests.length > 0) ?<NotificationsIcon color='error'/>
                      :<AccountBoxIcon/>}
                    >
                      Account
                    </Button>
                  </MenuItem></>}
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </Slide>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func,
};

export default AppAppBar;