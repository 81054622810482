
import React, { useEffect, useMemo } from 'react';
import { Box, Card, CardContent, Fade, Icon, LinearProgress, Link, Stack, Typography } from '@mui/material';
import ReactJson from 'react-json-view';
import { H2, P } from '../MUI/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import axios from 'axios';
import IconButton from '@mui/material/IconButton';
import InfoButtonComponent from '../MUI/InfoButtonComponent';
import ChevronDownIcon from '@mui/icons-material/ExpandMore';
const feilds = [
    
    {key:'ceterms:description',label:'Description'},
    {key:'ceterms:subjectWebpage',label:'Subject Webpage'},
    {key:'ceasn:competencyText',label:'Competency Text'},
    {key:'credentialFinderUrl',label:'CredentialFinderUrl'},
    {key:'ceterms:inLanguage',label:'Language'},
    {key:'@type',label:'Type'},


]
/**
 * Renders a card component for displaying credential engine information.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.item - The credential engine item.
 * @param {boolean} props.selected - Indicates if the card is selected.
 * @param {Function} props.onClick - The click event handler.
 * @param {boolean} props.expanded - Indicates if the card is expanded.
 * @param {Function} props.onExpand - The expand event handler.
 * @param {Object} props.owner - The owner of the credential engine.
 * @returns {JSX.Element} The rendered card component.
 */
const CredentialEngineCard = ({item,selected,onClick,expanded,onExpand,owner}) => {

    const [ownedBy, setOwnedBy] = React.useState([]);
    const [offeredBy, setOfferedBy] = React.useState([]);
    const [ownedByUrls,setOwnedByUrls] = React.useState(item?.['ceterms:ownedBy']);
    const [offeredByByUrls,setOfferedByUrls] = React.useState(item?.['ceterms:offeredBy']);
    const getOwnedOrOfferedBy = (ownedBy,offeredBy) => {
        if(ownedByUrls?.length>0){
            return(<> <Typography variant='caption' color='text.secondary'>Owned By:</Typography>
               { ownedBy?.length>0 ? 
                ownedBy.map((data)=>( 
                     <div><P>{data?.['ceterms:name']?.['en-US'] || data?.['ceterms:name']?.['en-us']}</P></div>
                    ))
                    : <LinearProgress sx={{maxWidth:'70px',opacity:0.5}} color='inherit'/>}
            </>)
        }
        if(offeredByByUrls?.length>0){
            return (<><Typography variant='caption' color='text.secondary'>Offered By</Typography>
                {offeredBy?.length>0 ? 
                
                offeredBy.map((data)=>( 
                     <div><P>{data?.['ceterms:name']?.['en-US'] || data?.['ceterms:name']?.['en-us']}</P></div>
                    ))
                    : <LinearProgress sx={{maxWidth:'70px',opacity:0.5}} color='inherit'/>}
            </>)
        }
        return null;
    }
    useEffect(() => {
        async function getOwner(){
            
                try{
                    
                    if(owner && !owner.isEmpty){
                        
                        setOwnedBy([owner]);
                        setOfferedBy([owner]);
                        console.log("Owner:",owner);
                    }else if(expanded || owner.isEmpty){
                        
                if(item?.['ceterms:ownedBy']){
                    const data = await Promise.all(item?.['ceterms:ownedBy'].map(async(data)=>await axios.get(data)));
                    //console.log("OwnedBy:",data.map((data)=>data?.data));
                    setOwnedBy(data.map((data)=>data?.data));
                }
                
                    
                    if(item?.['ceterms:offeredBy']){
                        const data = await Promise.all(item?.['ceterms:offeredBy'].map(async(data)=>await axios.get(data).catch(e=>{setOfferedBy([])})));
                        //console.log("OfferedBy:",data.map((data)=>data?.data));
                        setOfferedBy(data.map((data)=>data?.data));
                        
                    }else{
                        //console.log("No Offered By",item);
                    }
                }
                }catch(e){
                    setOwnedBy([]);
                    setOfferedBy([]);
                    console.log("Error:",e);
                }
            
        }
        
getOwner();
}, [ownedByUrls,offeredByByUrls,expanded,owner]);
    return (
        // item['@type'] === 'ceterms:QACredentialOrganization' && 
        (
        <Card sx={{width:'95%',
        transition:'all 50ms ease-in-out',
        bgcolor:selected&&'#eee' ,
        cursor:'pointer', 
        borderRadius:2,
        
        border:'1px solid #ccc',
        '&:hover': { 
            scale: '1.01',
             bgcolor:'#efefe' 
        }}} 
        
        >
            <CardContent>
            <Stack direction='row' spacing={1} width='100%' alignItems='center'> 
            <IconButton><ChevronDownIcon onClick={onExpand} color='inherit' sx={{transform:expanded&&'rotate(-180deg)',transition:'all 0.5s ease-in-out'}}/></IconButton>
            
            <Stack direction='row' spacing={1} width='100%' onClick={onClick}> 
                {selected ? <CheckCircleIcon color='success'/> : <CheckCircleOutlineIcon color='disabled'
                />}
                <Stack direction='row' spacing={1} textAlign='center' alignItems={'center'}>
                <Typography variant="h5" component="div">
                {item?.['ceterms:name']?.['en-us'] ||item?.['ceterms:name']?.['en-US']}
            </Typography>
            {getOwnedOrOfferedBy(ownedBy,offeredBy)}
                </Stack>
           
            </Stack>
            <InfoButtonComponent src={[item,{"Offered By":offeredBy}]} title='Registry Data'/>
            {/* <Stack direction='row' width='10%' justifyContent='flex-end'/> */}
            </Stack>
            <Box onClick={onClick}>
                {expanded &&<>
            {ownedByUrls?.length>0 && <><H2>Owned By:</H2>
          
                {ownedBy?.length>0 ? 
                
                ownedBy.map((data)=>( 
                     <div><P>{data?.['ceterms:name']?.['en-US'] || data?.['ceterms:name']?.['en-us']}</P></div>
                    ))
                    : <LinearProgress sx={{maxWidth:'70px',opacity:0.5}} color='inherit'/>}
                
                    </>
                    } 
            {(offeredByByUrls>0 || !offeredBy) && <><H2>Offered By:</H2>
          
                {offeredBy?.length>0 ? 
                
                offeredBy.map((data)=>( 
                     <div><P>{data?.['ceterms:name']?.['en-US'] || data?.['ceterms:name']?.['en-us']}</P></div>
                    ))
                    : <LinearProgress sx={{maxWidth:'70px',opacity:0.5}} color='inherit'/>}
                
                    </>
                    } 
                    
            {feilds.map(feild=>(
                item?.[feild.key]?.['en-us'] || item?.[feild.key]?.['en-US'] || item?.[feild.key] ? 
                <><H2>{feild.label}:</H2> <P>{item?.[feild.key]?.['en-us'] || item?.[feild.key]?.['en-US'] || item?.[feild.key]}</P></> : null
                
            ))
            }
            <><H2>Credential Finder URL:</H2> <Link target='_blank'href={item?.['@id']?.replace('engineregistry','finder').replace('sandbox.','')}>{item?.['@id']?.replace('engineregistry','finder').replace('sandbox.','')}</Link></> 
            </>}
            
            </Box>
            
           
           
            </CardContent>
            {/* <ReactJson src={item} collapsed={1}/>    */}
        </Card>
        )
    );
};

export default CredentialEngineCard;