import { Editor as EditorComponent } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './Editor.css';
import { Stack } from '@mui/material';



/**
 * Editor component. This component is responsable for rendering the editor for the email template components. Based on the react-draft-wysiwyg library.
 * 
 * @param {Object} props - The component props.
 * @param {Object} props.state - The state object.
 * @param {Function} props.setState - The function to set the state.
 * @param {Object} props.defaultProp - The default prop object.
 * @returns {JSX.Element} The rendered Editor component.
 */
export const Editor = ({state,setState, defaultProp}) =>{

    const changeParentState = (e) => {
        setState(e);
    }
  return (
    <Stack border={1} height='900px'>
    <EditorComponent
    // contentState={state}
    wrapperClassName='wrapper-class'
    editorClassName='editor-class'
    toolbarClassName='toolbar-class'
    onChange={(e)=>changeParentState(e)}
    defaultContentState={defaultProp}
  
    /></Stack>
  );
}