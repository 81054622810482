import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/material/styles';
import { Alert, Box, IconButton, Snackbar, Stack, Typography } from '@mui/material';
import {useTheme} from '@mui/material/styles';
import { H2 } from './Typography';
import {Slide} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

/**
 * NotifyPopup component displays a notification popup with customizable options. Based off of the MUI Snackbar component as well as the Alert component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.message - The message to be displayed in the notification.
 * @param {function} [props.onClose=()=>{}] - The callback function to be called when the notification is closed.
 * @param {Object} [props.sx] - The custom styling for the Snackbar component.
 * @param {string} [props.severity='warning'] - The severity level of the notification.
 * @param {number} [props.autoHideDuration=5000] - The duration in milliseconds for the notification to automatically hide.
 * @param {boolean} [props.confirm=false] - Determines whether the notification requires confirmation.
 * @param {ReactNode} [props.confirmButtom=undefined] - The custom confirmation button to be displayed.
 * @param {boolean} [props.bottom=false] - Determines whether the notification should be displayed at the bottom of the screen.
 * @returns {JSX.Element} The NotifyPopup component.
 */
const NotifyPopup = ({ message,onClose=()=>{},sx,severity='warning',autoHideDuration=5000,confirm=false,confirmButtom=undefined,bottom=false}) => {
    const [open, setOpen] = useState(true);
    const theme = useTheme();
    useEffect(() => {
        const timer = setTimeout(() => {
            setOpen(false);
            onClose();
        }, !confirm? autoHideDuration:9999999);

        return () => clearTimeout(timer);
    }, []);

    return (
        <Snackbar
            color={severity}
            TransitionComponent={Slide}
            anchorOrigin={{ vertical: (bottom?'bottom':'top'), horizontal: 'center' }}
            sx={{
                borderRadius: 2,
                width: '40%',
                bgcolor: theme?.palette?.mode === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)',
            ...sx}}
            open={open}
            autoHideDuration={!confirm? autoHideDuration:99999999}
            onClose={(e) => {setOpen(false);onClose(e)}}
        >  
                <Alert
        onClose={()=>{onClose();setOpen(false)}}
        severity={severity}
        variant="standard"
        sx={{ width: '100%',borderRadius:2,border:'1px solid',borderColor:severity}}
        TransitionComponent={Slide}
        closeText="Close"
                
      >
        <Stack direction='row' spacing={2} justifyContent='space-between' width='100%' sx={{my:confirm&&-0.5}}>
        <Box display="flex" alignItems="center">
            <Typography variant='h4' fontSize={15}>{message}</Typography>
            </Box>
            {confirm && confirmButtom}
            </Stack>
            </Alert>
        </Snackbar>

          
    );
};

export default NotifyPopup;