
import CredentialForm from "./CredentialForm";
import { AppContext } from "../../AppContext";
import { useContext ,useState,useEffect,scrollToTop, cloneElement} from "react";
// import JsonRefs from 'json-refs';
// import "../../schemas"
import currentEmployment from "../../schemas/employment/employment-current-v1.1.schema.json";
import currentEmploymentdes from "../../schemas/employment/employment-current-v1.1.descriptor.json";
import ReactJson from 'react-json-view'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
import Switch from '@mui/material/Switch';
// import { Resolver } from "@stoplight/json-ref-resolver";
import get from '../../Hooks/getRequest.js'; 
import requests from '../../Api/requests.js';
import OfferForm from './EmploymentOfferForm.js';
import { Box, Icon, Paper, Stack, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import { H2, H3,P } from "../MUI/Typography";
import {CircularProgress }from "@mui/material";
import{ LinearProgress} from "@mui/material";
import Fade from '@mui/material/Fade';
import EmploymentOfferForm from "./EmploymentOfferForm";
import BadgeOfferForm from "./BadgeOfferForm";
import sendOffer,{proccessOfferFormData} from "../../Hooks/sendOffer.js";
import { TextField } from "@mui/material";
import { useTheme } from "@mui/material";
import axios from "axios";
import { ToggleButton } from "@mui/material"
import RecordReader from "./RecordReader.js";
import PopoverComponent from "../MUI/PopoverComponent";
//REMOVE
import  clrSchema from "../../schemas/Layer2/clr.json";
import {Button} from "@mui/material"
import CredentialEngineSearch from "../CredentialEngineSearch/CredentialEngineSearch.js";
import DeleteIcon from "@mui/icons-material/DeleteForever.js";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoButtonComponent from "../MUI/InfoButtonComponent.js";
import NotifyPopup from "../MUI/NotifyPopup.js";
import { CredEngineToVelocity } from "../../Api/index.js";
import LoadingIconButton from "../MUI/LoadingIconButton.js";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CheckIcon from '@mui/icons-material/Check';  
import NonVelocitySwitch from "../MUI/NonVelocitySwitch.js";
import EducationOfferForm from "./EducationOfferForm.js";


export const hyphenToCamel = {
  'employment-current-v1.1':'EmploymentCurrentV1.1',
  'employment-past-v1.1':'EmploymentPastV1.1',
  'assessment-v1.1':'AssessmentV1.1',
  'certification-v1.1':'CertificationV1.1',
  'license-v1.1':'LicenseV1.1',
  'course-attendance-v1.1':'CourseAttendanceV1.1',
  'course-completion-v1.1':'CourseCompletionV1.1',
  'course-registration-v1.1':'CourseRegistrationV1.1',
  'education-degree-graduation-v1.1':'EducationDegreeGraduationV1.1',
  'education-degree-registration-v1.1':'EducationDegreeRegistrationV1.1',
  'education-degree-study-v1.1':'EducationDegreeStudyV1.1',
  'ncsbn-multi-license-v1.0':'NCSBNMultiLicenseV1.0',
  'open-badge-v2.0':'OpenBadgeV2.0',
  'clr':'ClrSubject'
} 
export const layer2=[
  'ncsbn-multi-license',
  'clr'
]
export const extensions={
  'employment-current':'employment-current-v1.1',
  'employment-past':'employment-past-v1.1',
  'assessment':'assessment-v1.1',
  'certification':'certification-v1.1',
  'license':'license-v1.1',
  'course-attendance':'course-attendance-v1.1',
  'course-completion':'course-completion-v1.1',
  'course-registration':'course-registration-v1.1',
  'education-degree-graduation':'education-degree-graduation-v1.1',
  'education-degree-registration':'education-degree-registration-v1.1',
  'education-degree-study':'education-degree-study-v1.1',
  'open-badge':'open-badge-v2.0',
  'ncsbn-multi-license':'NCSBNMultiLicensev1.0',
  'clr':'clr'
}
const filterProps = ['Json-ld-context_Schema']


// const filter = (data) => {
//   if(data.definitions){
//     for(const prop of filterProps){
//       if(data.definitions[prop]){
//         delete data.definitions[prop];
//       }else{
//         console.log("Property",data['definitions.Json-ld-context_Schema'],"not found on ",data);
//       }
//     }
//   }
//   return data;
// }

const simpleForms={
  employment: (<EmploymentOfferForm/>),
  badge: (<BadgeOfferForm/>),
  clr: (<RecordReader type='clr'/>),
  'education-degree': (<EducationOfferForm/>),
  'ncsbn-multi-license': (<RecordReader type='ncsbn-multi-license'/>),
}
export const getDescriptor = async (type) => {
  try{
  const filename = Object.keys(hyphenToCamel).find(key =>
    hyphenToCamel[key] === type);
    const folderName = layer2.includes(type) ? 'Layer2' : allOptions.find((option) => option.value.includes(Object.keys(extensions).find(key =>
      extensions[key] === filename))).key;
  const descriptor = await import(`../../schemas/${folderName}/${filename}.descriptor.json`);
  // console.log("Descriptor:",descriptor);
  // console.log("File:",filename);
  return descriptor;
    }catch(err){
      console.log(err);
      return {};
    }
}
export const allOptions = [
  { key: 'employment', value: ["employment-current","employment-past"] },
  { key: 'assessment', value: ["assessment"] },
  { key: 'certification', value: ["certification","license"] },
  { key: 'course', value: ["course-attendance","course-completion","course-registration"] },
  { key: 'education-degree', value: ["education-degree-graduation",'education-degree-registration','education-degree-study'] },
  { key: 'badge', value: ["open-badge"] },
  { key: 'clr', value: ["clr"] },
  { key: 'ncsbn-multi-license', value: ["ncsbn-multi-license"] },

];
/**
 * Component for rendering the CredentialSection page, uses a variety of states to control the flow.
 * 
 * @component
 * @example
 * // Usage
 * <CredentialSection />
 */
const CredentialSection = () => {

    const {type,setType,client} = useContext(AppContext);
    const [schema,setSchema] = useState(currentEmployment);
    const [uiSchema,setUiSchema] = useState(currentEmploymentdes);
    const [formData,setFormData] = useState({});
    const [isSimple, setSimple] = useState(true);
    const [option,setOption]  = useState("employment-current");
    const [loading,setLoading] = useState(false);
    const [flag,setFlag] = useState(false);
    const [email,setEmail] = useState(undefined);
    const [error,setError] = useState(undefined);
    const theme = useTheme();
    const [success,setSuccess] = useState(undefined);
    const [search,setSearch] = useState(false);
    const [alignmentSelections,setAlignmentSelections] = useState([]);
    const [offer,setOffer] = useState(undefined);
  const [loadingMessage,setLoadingMessage] = useState('Loading...');
    const [useVelocity,setUseVelocity] = useState(true);
    useEffect(() => {
      setUseVelocity(true);
      
    },[type,option,isSimple]);
    useEffect(() => {
      console.log("useVelocity:",useVelocity);
      
    },[useVelocity]);


    const pushSelections = (selection,owner) => {
      setLoading(true);
      setLoadingMessage('Adding Credential...');
      Promise.all(selection.map( async(data,index) =>{ 
      const ceData = (await axios.get(data)).data;
        if(index === 0){
          await setFormData(await CredEngineToVelocity(ceData,type));
        }
      return {
        type:'AlignmentObject', 
        targetName:ceData?.['ceterms:name']?.['en-US'] || ceData?.['ceterms:name']?.['en-us'],
        targetDescription:ceData?.['ceterms:description']?.['en-US'] || ceData?.['ceterms:description']?.['en-us'],
        targetCode:ceData?.['ceterms:ctid'],
        targetUrl:`https://credentialfinder.org/resources/${ceData?.['ceterms:ctid']}` || ceData?.['ceterms:subjectWebpage'],
        targetFramework:'Credential Engine Registry'
      };
      })).then((data)=>{
        
        setAlignmentSelections(data)
        setLoading(false);
        setLoadingMessage('Loading...');
      });
      setSearch(false);
      
    }
    
    const onSubmit = ({formData}) => {
      setError(undefined);
      setSuccess(undefined);
      setOffer(undefined);

      formData.email = email;
      formData.type = hyphenToCamel[extensions[option]];
      formData.nonVelocity = !useVelocity;
      // console.log("formData:",formData);
      setFormData(formData);
      // if(alignmentSelections.length > 0){
      //   if(!formData.alignment){ formData.alignment = alignmentSelections}else{;
      //     formData.alignment = [...formData.alignment, ...alignmentSelections];
      //   }
      //   console.log("Alignment Added:",formData.alignment);
        
      // }
      
      console.log('useVelocity:',useVelocity);  
      const proccessres = proccessOfferFormData(formData);
      if(layer2.includes(type)){

      }else{
      if(proccessres?.error){
        
        setError(proccessres?.error);
        }else{
          //Add selected ALingments to offer
          
          console.log("Proccessed Offer:",proccessres);
            sendOffer(proccessres).then((res) => {
              console.log(res);
              if(res.error){
                setError(res?.error.errors || res.error);
              }else{
                setSuccess('Offer Sent Successfully');
                console.log("Offer Sent Successfully",res);
                setOffer(res?.offer);
              }
            }).catch((err) => {
              setError(err.error || err);
            });
        }
      }
      window.scrollTo(0,0);
    };

    useEffect(() => {
      if(alignmentSelections.length > 0){
        setSuccess('Credential Added');
      }
      setFormData({...formData,alignment:alignmentSelections});
    },[alignmentSelections]);



    const toggleSimple = () => setSimple(!isSimple);
    
    const importComponent = async (option) => {
      // const ui = await import(`../../schemas/${type}/${option}-v1.1.schema.json`);
      try{
        setFormData(undefined);
      var ui,module;
      if(layer2.includes(type)){
        // console.log("Layer 2");
        ui = await import(`../../schemas/Layer2/${extensions[option]}.descriptor.json`);
        if(extensions[option] === 'clr'){
          module = (clrSchema);
        }else{
        module = await import(`../../schemas/Layer2/${extensions[option]}.json`);
        }
        // module = await get({url:`${requests.networkRegistrar}/api/v0.6/schemas/ncsbn-multi-license-v1.0`});
        if(module.error){
          setError(module?.message || module.error);
        }
      }else{
      ui = await import(`../../schemas/${type}/${extensions[option]}.descriptor.json`);
      module = await import(`../../schemas/${type}/${extensions[option]}.schema.json`);
      }
      await setSchema(module);
      await setUiSchema(ui);
      setLoading(false);
    }catch(err){
      await setError(err?.message || err);
    }
    };



      // console.log(allOptions.findIndex((option) => option.key === type));

    useEffect(() => {
      setError(undefined);
      setAlignmentSelections([]);
    },[type]);

    const onSelect = (option) => {
      setLoading(true);
      setOption(option.value);
      setFlag(false);
      importComponent(option.value);
    };
    if(allOptions[allOptions.findIndex((option) => option.key === type)]){
      if(!allOptions[allOptions.findIndex((option) => option.key === type)].value.includes(option) && !flag){
        setFlag(true);
        onSelect({value:allOptions[allOptions.findIndex((option) => option.key === type)].value[0]});
        
      }
    }
  
    return (
      <>
        <Stack direction={{md:'column',lg:'column'}}  justifyContent='space-evenly' spacing={5} height='100%'>




<PopoverComponent open={search} onClose={()=>setSearch(false)} fullWidth={true} topClose={true} title='Search Credential Engine Registry' >

<CredentialEngineSearch ctid={client?.ctid} pushSelection={pushSelections} type={type}/>

</PopoverComponent>





      <Stack direction='column' pt={{xs:8,sm:7,md:7,lg:7}} spacing={1}>
        {(alignmentSelections?.length <= 0 && !type.includes('employment')) &&
      <Button onClick={()=>setSearch(true)} variant='outlined' fullWidth color='inherit' sx={{bgcolor:'#FFF'}}><Stack direction={'row'} spacing={3} alignItems='center'><img width='25px'src='https://www.floridaemop.com/wp-content/uploads/credential-engine-logo.png'>
      
      </img><P pt={0.5}>Search Credential Engine Registry</P></Stack></Button>}
        {(!isSimple || !simpleForms[type] || layer2.includes(type)) && <>
      <H3>Credential Specification</H3>
      {allOptions[allOptions.findIndex((option) => option.key === type)] &&
    <Dropdown className = 'dropdown' options={allOptions[allOptions.findIndex((option) => option.key === type)].value} onChange={onSelect} value={allOptions[allOptions.findIndex((option) => option.key === type)].value[0]} placeholder="Select an option" />
      }</>}
      {/* Uses alternate form then the dynamic CredentialForm component */}
      {simpleForms[type] &&

        (
          
          
          
          /* Uses buttonGroup for layer 2 creds */
        layer2.includes(type) ?
<ToggleButtonGroup
color="standard"
value={isSimple}
exclusive
onChange={(e)=>setSimple(e.target.value ==='true')}
sx={{
  border: '1px solid',
  maxWidth: '300px',
  maxHeight: '50px',
}}
>
  <ToggleButton sx={{
  border: ! isSimple &&'1px solid',borderRight: isSimple&&'1px solid'}} fullWidth value={false}>Enter By Form</ToggleButton>
  <ToggleButton sx={{
  border: isSimple &&'1px solid'}}fullWidth value={true}>Existing record</ToggleButton>
</ToggleButtonGroup> : 

      // Uses switch for layer 1 creds
        <Stack spacing={2} direction="row" alignItems='center'>
        <H2 sx={{ padding: '10px' }}>Use Advanced Form</H2>
        <Switch onChange={toggleSimple} checked={!isSimple}/>
      
      
      
      </Stack>)}

        


      </Stack>
    
    <Stack className="credential-section"
    direction='column'
    justifyContent='flex-start'
    px={3} component={Paper}
    sx={{
      backgroundColor:theme.palette.mode === 'dark' && theme.palette.grey[800]
    }}
    >
             <Fade
          in={loading}
          style={{
            transitionDelay: '500ms',
          }}
          unmountOnExit

        >
          <Stack direction='row' width='100%' alignItems={'center'}>
    
            <Typography variant='h5' width={10*loadingMessage.length}>{loadingMessage}</Typography>
            
           
            
            <LinearProgress sx={{height:'10px',width:'100%'}}/> 
           
             </Stack>
         </Fade>
         {/* if CredentialEngine alignment is included */}
        {alignmentSelections?.length > 0 && 
        <Stack direction='row' 
        spacing={1} 
        alignItems='center' 
        justifyContent='center'
         width='100%' 
         border='1px solid' 
         borderBottom='none'
         borderRadius='4px' 
         bgcolor='#d9f2e6' 
         height='30px'
         sx={{
          transition:'all 0.2s ease-in-out',
         '&:hover':{
            // bgcolor:'#f0f5f5'
            height:'35px'
          }
        }}
         >
           <img width='20px' height='20px' src='https://www.floridaemop.com/wp-content/uploads/credential-engine-logo.png'/>
          <Box display="flex" alignItems="center">
    <Typography variant="h2" sx={{ fontSize: 15 }} color='inherit'>
      {alignmentSelections.length} Alignment{alignmentSelections.length > 1 && 's'} Included
    </Typography>
  </Box>
  <InfoButtonComponent src={alignmentSelections} title='Included Alignments'/>
          <IconButton onClick={()=>setAlignmentSelections([])}><DeleteIcon/></IconButton>
          {/* <IconButton onClick={()=>setSuccess('fefefefef')}><DeleteIcon/></IconButton> */}
      </Stack>
          }

    {error && <NotifyPopup message={error} severity='error' onClose={()=>setError(undefined)}/>}
      {/* CONFIRMATION TO SEND EMAIL BUTTON */}
    {success && <NotifyPopup message={success==='Offer Sent Successfully' ? `Offer Created! Notify ${email} by Email?` : success} 
    severity='success' 
    onClose={()=>setSuccess(undefined)} 
    confirm={success==='Offer Sent Successfully'} 
    confirmButtom={
    <LoadingIconButton
    icon={<CheckIcon/>}
    doneIcon={<MarkEmailReadIcon/>}
    useView={{canRemove:true,emailLoading:true,...offer}}
    />
  }
    />}

    {isSimple && simpleForms[type] ? cloneElement(simpleForms[type],{schema:schema,alignments:alignmentSelections}) :
    // If not simple form
    <Stack direction='column' spacing={2} pt={2}>
      <NonVelocitySwitch onChange={()=>setUseVelocity(!useVelocity)} value={!useVelocity}/>
  
      <TextField
        label="Recipient Email"
        variant="outlined"
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {!loading && <CredentialForm 
      schema={type==='clr'? schema:schema}
      uiSchema={uiSchema}
      formData={formData}
      onSubmit={onSubmit}
      name = {option}
      />}
      
      
      </Stack>}
    </Stack>
    </Stack>
    </>
    );
}
export default CredentialSection;