import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';


/**
 * AutoComplete component used to auto fill from a set of data.
 * 
 * @param {Object[]} options - The options for the AutoComplete component. format: [{label: 'label', value: 'value'}]
 * @param {Function} onChange - The callback function to be called when the value of the AutoComplete component changes.
 * @returns {JSX.Element} The AutoComplete component.
 */
const AutoComplete = ({options,onChange}) => {

    return (
        <Autocomplete
        clearIcon={<ClearIcon/>}
        // popupIcon={<ClearIcon/>}
        onChange={(event, newValue) =>onChange(newValue?.label)}
            options={options}
            renderInput={(params) => (
                <TextField {...params} 
                label="" 
                placeholder='🔍 Search user email...'
                variant="outlined" 
                />
            )}
        />
    );
};

export default AutoComplete;