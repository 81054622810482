import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';
import Email from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CircularProgress from '@mui/material/CircularProgress';
import Logo from '../../assets/images/icon.png';
import {defaultEmailTemplate,defaultEmailHtml } from '../../config';
import {sendEmail as pushEmail} from '../../Hooks/sendEmail.js';
import draftToHtml from 'draftjs-to-html';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import generateQRCode from '../../Hooks/generateQRCode.js';
import { QRCode } from 'react-qrcode-logo';
import { getDefaultMailTemplate } from '../../Hooks/mailing/index.js';
import html2canvas from 'html2canvas';
import NotifyPopup from './NotifyPopup.js';


const sendEmail =  async (data) => {
    console.log("Data from sendEmail:",data);
    var template = await getDefaultMailTemplate();
    if(template?.error){
        return {error:"Error in getting default mail template"};
    }
    if(!template?.template){
        template = {template:defaultEmailHtml};
    }else{
        template = {template: draftToHtml(template.template)};
    }
    const res = pushEmail({
        offerId: data._id,
        subject: data.subject || 'Credential Offer Notification',
        html: template.template,
        qrCode: data.qrCode,
        uri: data.uri,
        url: data.url,
    }).catch(err => {console.log("Error in sending email",err);return new Promise((resolve,_) => resolve({error:"Error in sending email"}));});
   console.log("Res:",res);
    return res;
    // return {message:"Error in sending email"};
}
function blobToBase64(blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

/**
 * Represents a loading icon button component. This component is also responsable for sending an email with a QR code and link. 
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.icon - The icon to display in the button.
 * @param {Function} props.clicked - The function to call when the button is clicked.
 * @param {boolean} props.isLoading - Indicates whether the button is in loading state.
 * @param {React.ReactNode} props.doneIcon - The icon to display when the loading is done.
 * @param {string} props.id - The unique identifier for the button.
 * @param {boolean} props.useView - The view to use.
 * @returns {React.ReactNode} The rendered LoadingIconButton component.
 */
const LoadingIconButton = ({ icon, clicked, isLoading,doneIcon,id,useView,...props }) => {
    const [loading, setLoading] = React.useState(false);
    const [done,setDone] = React.useState(false);
    const [error, setError] = React.useState(undefined);
    const [uri, setUri] = React.useState(undefined);
    const [url, setUrl] = React.useState(undefined);
    const [blob, setBlob] = React.useState(undefined);
     const ref = React.useRef();    


    React.useEffect(() => {
        if(blob){
        sendEmailStart();
        }
    }, [blob]);

    const startEmailProccess =()=>{
        setLoading(true);
        const {canRemove,emailSent,...sendOffer} = useView;
        generateQRCode(sendOffer).then((data) => {
            if(data.error){
                setError(data.error);
                setLoading(false);
                return;
            }
            if(!data.uri){
                return {error:"Error in generating QR code"};
            }   
            if(data.url){
                setUrl(data.url);
            }
            setUri(data.uri);
        });
    }
    const getQrCodeBlob = () => {
       try{
        console.log(document.querySelector("#qrCodeEl"))
        //converts component to image
        const d =  html2canvas(document.querySelector("#qrCodeEl"),{scale : 0.4}).then(canvas => {
            console.log("Canvas:",canvas);
            canvas.toBlob((blob) => {
                blobToBase64(blob).then((data) => {
                // console.log("Blob:",data);
                setBlob(data);
                }).catch(err => console.log("Error in converting blob to base64",err));
            });
        }).catch(err => console.log("Error in getting blob",err));
        return d;
        }catch(err){
            console.log("Error in getting blob",err);
        }
    }

    const sendEmailStart =  () => {
        setError(undefined);
        const res = sendEmail({qrCode:blob,uri:uri,url:url,...useView}).then((data) => {
            setLoading(false);  
            if(data.error){
                setError(data.error);
            }else{
                setDone(true);
            }
        }).catch(err => {
            console.log("Error in sending email",err);
            setError("Error in sending email"+err);setLoading(false);});  
        setUri(undefined);
        setBlob(undefined); 
        
    };
    useEffect(() => {
        if(loading){
            setLoading(false);
        }
    }, [error]);    
    return (<>

        {error && <NotifyPopup message={"Error Sending Email:"+error?.message} severity='error' />}

        {uri && <QRCode value={uri} id='qrCodeEl' size={500} quietZone={10}
        style={{zIndex: -100000,position: 'absolute',top: -10000,left: 0}}
            logoImage={Logo} 
            bgColor='#F0F0F0'
            //  logoPadding={3} 
            qrStyle='squares'
            fgColor='#404040'
            eyeRadius={3}
            eyeColor={'#404040'}
            logoOnLoad={!done ? ()=> getQrCodeBlob() : ()=>{setUri(undefined);setLoading(false);}}
            logoOpacity={1}
            logo
            cloneNode={true}
            />}
        
        <IconButton className={`icon-button ${isLoading ? 'loading' : ''}`} onClick={(e)=>{
            startEmailProccess();
            // setError(new Error("Error in sending email"));
            }} id={id} color={ error ? 'error' : done ? 'success' : 'grey.300'}
            
            title='Notify By Email'
            >
            {Boolean(error) ? <CancelScheduleSendIcon/> : (loading ? <CircularProgress size={24} /> : (done ? doneIcon : icon))}
            
        </IconButton></>
    );
};



export default LoadingIconButton;