import React from 'react';
import { Box, Paper, Popover } from '@mui/material';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';





/**
 * PopoverComponent is a reusable component that renders a popover with customizable options.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {boolean}open  - Determines whether the popover is open or closed.
 * @param {function} onClose  - Callback function to be called when the popover is closed.
 * @param {ReactNode} children  - The content to be rendered inside the popover.
 * @param {boolean} fullWidth - Determines whether the popover should take up the full width of the screen.
 * @param {boolean} showBackground  - Determines whether to show a background for the popover.
 * @param {boolean} topClose - Determines whether to show a close button at the top of the popover.
 * @returns {ReactNode} The rendered PopoverComponent.
 */
const PopoverComponent = ({open,onClose,children,
    fullWidth=false,
    showBackground=false,
    topClose=false
    ,...props}) => {
    return (
        <Popover
        component={!showBackground && Paper}

        elevation={10}
        open={open}
        onClose={()=>onClose()}
        anchorReference='none'
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border:'1px solid',
          }}
          width= {fullWidth ? window.innerWidth :'1000px'}
          {...props}
        >
            {topClose &&<Button onClick={onClose} color="inherit" variant='outlined' endIcon = {<CloseIcon fontSize='xl'/>} sx={{mt:1,ml:1}}>Close</Button>}
            <Box sx={{m:5}}>
            {children}
            {!topClose &&<Button onClick={onClose} color="error" variant='outlined' endIcon = {<CloseIcon/>}>Close</Button>}
            </Box>
        </Popover>
    );
};

export default PopoverComponent;