import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Box, Button, IconButton, Paper, Stack, Tooltip } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { QRCode } from 'react-qrcode-logo';
import { H2, P } from '../MUI/Typography';
import Logo from '../../assets/images/icon.png';
import NotifyPopup from '../MUI/NotifyPopup';
import { getDisclolsureQRCode } from '../../Hooks/disclosures/requests';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { getMailTemplates } from '../../Hooks/mailing';
import { defaultDisclosureTemplate } from '../../config';
import SelectComponent from '../MUI/SelectComponent';
import SendDisclosureEmailComponent from './SendDisclosureEmailComponent';
import { DownloadOutlined } from '@ant-design/icons';
const DisclosureRequestComponent = ({disclosure}) => {
    const [uri, setUri] = React.useState(undefined);
    const [url, setUrl] = React.useState(undefined);
    const [email, setEmail] = React.useState('');
    const [emailList, setEmailList] = React.useState([]);
    const ref = React.useRef();
    const[ error, setError] = React.useState(undefined);
    const [templates, setTemplates] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [content, setContent] = React.useState('');
    const defaultInspectionOption = {label:'VelocityCreds Template',value:defaultDisclosureTemplate,id:0,type:'inspection'};
    const [selectValue, setSelectValue] = React.useState(defaultDisclosureTemplate);
   const [success, setSuccess] = React.useState(undefined);


    const handleDownload = () => {
		ref.current?.download('png', 'disclosure-qrcode.png');
	}
    const generateQR = () => {
        getDisclolsureQRCode(disclosure.disclosureId).then((data) => {
            if(data?.error){
                setError(data.error);
            }else{
                setUri(data.uri);
                setUrl(data.url);
            }
        }).catch((e) => setError(e));
    }

    const loadMailTemplates = () => {
        // fetch mail templates
        setError(undefined);
        setLoading(true);
        getMailTemplates().then((data) => {
          console.log("GetTemplates:",data);
          if(data.error){
            setError(data.error);
          }else{
            setTemplates([...data.map((data) => {
              return {label:data.name,value:data.template,id:data._id,type:data.type}}
            ),defaultInspectionOption].filter((template)=>{
            //   console.log("Template:",template.type,"Target:",targetType,'Result:',template.type === targetType);
              return template.type === 'inspection'}));
          }
          setLoading(false);
        });
      }

      useEffect(() => {
        loadMailTemplates();
      }, []);
    return (
        <Stack >
            {error && <NotifyPopup message={error} severity="error" onClose={()=>setError(undefined)}/>}
            {success && <NotifyPopup message={success} severity="success" onClose={()=>setSuccess(undefined)}/>}
            <Box component='form' onSubmit={(e)=>{
                if(email.includes('@') && email.includes('.') && !emailList.includes(email)){
                    setEmailList([...emailList,email]);
                    setEmail('');
                }else if(emailList.length<=0){
                    setError('No Emails Included');
                    return
                }
                
                e.preventDefault()
                }}>
                <Stack spacing={1} pb={2}>
                {emailList.map((email,index) => <Box direction='row' key={index} component={Paper} elevation={2} borderRadius='30px' textAlign='center'>
                    {email}
                    <IconButton sx={{alignSelf:'flex-end'}}onClick={()=>setEmailList(emailList.toSpliced(index,1))}><CloseIcon/></IconButton>
                    </Box>)}
                </Stack>
                <Stack direction='row' display='flex' justifyContent='center' alignItems='center'>
                <TextField 
                label="✉️ Recipient Email(s)" 
                variant="outlined" 
                fullWidth 
                type='email'
                onChange={(e)=>setEmail(e.target.value)}
                value={email}
                title='Email'
                onBlur={()=>{
                    if(email.includes('@') && email.includes('.') && !emailList.includes(email)){
                        setEmailList([...emailList,email]);
                        setEmail('');
                        }else{
                            setError('Invalid Email');
                        }
                }}
                onKeyDown={(ev) => {
                    console.log(`Pressed keyCode ${ev.key}`);
                    if (ev.key === 'Enter') {
                        if(email.includes('@') && email.includes('.') && !emailList.includes(email)){
                            setEmailList([...emailList,email]);
                            setEmail('');
                            }else{
                                setError('Invalid Email');
                            }
                      ev.preventDefault();
                    }
                  }}
                 
               
                />
                
                <IconButton
                title='Add Email'
                 onClick={()=>{
                    if(email.includes('@') && email.includes('.') && !emailList.includes(email)){
                    setEmailList([...emailList,email]);
                    setEmail('');
                    }else{
                        setError('Invalid Email');
                    }
                 }}
                ><AddIcon/></IconButton>
                 
                </Stack>
                <Stack spacing={0} py={2}>
                <SelectComponent options={templates} title='💌 Email Template' value={selectValue} onChange={(e)=>setSelectValue(e.target.value)}/>
                    <P color='grey.500'>More Isuing Templates can be created under 'Email Templates'</P>
                </Stack>
                <Stack direction='row' spacing={1}>
                <SendDisclosureEmailComponent variant="contained" color="primary" sx={{marginTop:'10px'}} 
                template={selectValue} 
                recipients={emailList} 
                disclosureId={disclosure.disclosureId}
                onSend={()=>setSuccess('Email Sent')}
                />
                
            <Button variant="outlined" color="inherit" sx={{marginTop:'10px'}} endIcon={<QrCodeScannerIcon/>} onClick={generateQR}>Generate QR</Button>
            </Stack>
            </Box>
        
          {uri ? <Stack>    <H2>Offer QR Code:</H2><QRCode value={uri} id='qrCodeEl' size={500} quietZone={10} 
          ref={ref}
          logoImage={Logo} 
          bgColor='#F0F0F0'
          qrStyle='squares'
          eyeRadius={3}
          eyeColor={'#404040'}
          logoOpacity={1}
          logo
          fgColor='#404040'
          /><a style={{color:'coral'}}href={url} target='_blank'>Offer Link</a>
          <Button sx={{width:'520px'}} variant='outlined' pt={3} endIcon={<DownloadOutlined/>} onClick={handleDownload}>Download</Button>
          
          </Stack> : <Stack pt={{lg:0,xl:10}} ml={10}></Stack>}

        </Stack>
    );
};

export default DisclosureRequestComponent;